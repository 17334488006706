@mixin abs-pos ($top: auto, $right: auto, $bottom: auto, $left: auto) {
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
	position: absolute;
}

@mixin widthheight ($wid: auto, $hei: auto) {
	width: $wid;
	height: $hei;
}

@mixin widthheightboth ($both: auto) {
	width: $both;
	height: $both;
}

@mixin widthheightcentered ($wid: auto, $hei: auto) {
	width: $wid;
	height: $hei;
	$halfwid:($wid/2)*-1;
	$halfheight:($hei/2)*-1.025;
	margin: $halfheight 0 0 $halfwid;
}

@mixin widthheightvertcenter ($wid: auto, $hei: auto) {
	width: $wid;
	height: $hei;
	$halfheight:($hei/2)*-1.025;
	margin-top: $halfheight;
}

@mixin pseudofill() {
	content: '';
	position: absolute;
	top: 0; left: 0;
	@include widthheightboth(100%);
}

@mixin absolutefill() {
	position: absolute; top: 0; left: 0;
	@include widthheightboth(100%);
}

@mixin all-text-white() {
	a, p, h1,h2,h3,h4,h5,h6,li {
		color: $bgwhite;
	}
	h4::before {
		background-color: $bgwhite;
	}
}

@mixin expand-icon-hover() {
	span {
		&::before {
			@include transform(translate3d(-3px, -3px, 0) scaleY(3));
		}
		&::after {
			@include transform(translate3d(-3px, -3px, 0) scaleX(3));
		}
	}
}

// EASING
$fluid: .7,.3,0,1;
$fluidless: .5,.5,0,1;
$smootheaseout: .62,.02,.34,1;
$intenseeaseout: 1,0,0,1;
$lessintenseperfect: .55,0,0.45,1;
$suckitin: .38,-0.32,.89,.34;
$smoothswing: 0.4, 0.3, 0.1, .4;
$gentleeasein: .5,0,.57,1;
$lineareaseout: .45,.65,.64,1;
$sweepinginout: .52,.01,.16,1;
$lineareasein: .52,.7,.41,1;
$gentleout: .11,.62,.3,.98;
$sweetout: .19,1,.22,1;
$bouncey: .1,.55,.29,1.44;

$bgwhite: rgb(242,242,242);
$nurture-black: rgb(39,40,46);

$project-color: rgb(200,200,200);

$mobile-desktop: 700px;
$mobile-small: 500px;
$laptop-desktop: 1200px;
$large-desktop: 1600px;
$giant-desktop: 2100px;