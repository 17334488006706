@import "bourbon/bourbon";
@import "mixins";

.home-bg {
	@include transition(0.25s cubic-bezier($sweepinginout));
	// background-color: RGB(188, 154, 212);
	
	li {
		@include absolutefill();
		@include transition(transform 0.75s cubic-bezier($sweepinginout));
		@include transform-origin(0 0);
		@include transform(translateX(-100vw));
		&:not(.home-front-bg) {
			z-index: 5;
		}
	}

	.vid-bg {
		background-color: #FFF;
		@include transition(0.85s cubic-bezier($sweepinginout));
		@include transform(scaleX(0));
		@include transform-origin( 0 0 );
	}
	
	&[data-home-state="video-full"] {
		.vid-bg {
			@include transform(scaleX(1));
		}
	}

	&[data-active-case="0"] li:nth-child(1),
	&[data-active-case="0"] li:nth-child(2),
	&[data-active-case="0"] li:nth-child(3),
	&[data-active-case="0"] li:nth-child(4),
	&[data-active-case="0"] li:nth-child(5),
	&[data-active-case="0"] li:nth-child(6),
	&[data-active-case="0"] li:nth-child(7),
	&[data-active-case="0"] li:nth-child(8),
	&[data-active-case="0"] li:nth-child(9),
	&[data-active-case="0"] li:nth-child(10),
	&[data-active-case="0"] li:nth-child(11),
	&[data-active-case="0"] li:nth-child(12),
	&[data-active-case="0"] li:nth-child(13),
	&[data-active-case="0"] li:nth-child(14),
	&[data-active-case="0"] li:nth-child(15) {
		@include transform(translateX(100vw));
	}

	&[data-active-case="0"] li.home-front-bg,
	&[data-active-case="1"] li:nth-child(1),
	&[data-active-case="2"] li:nth-child(2),
	&[data-active-case="3"] li:nth-child(3),
	&[data-active-case="4"] li:nth-child(4),
	&[data-active-case="5"] li:nth-child(5),
	&[data-active-case="6"] li:nth-child(6),
	&[data-active-case="7"] li:nth-child(7),
	&[data-active-case="8"] li:nth-child(8),
	&[data-active-case="9"] li:nth-child(9),
	&[data-active-case="10"] li:nth-child(10),
	&[data-active-case="11"] li:nth-child(11),
	&[data-active-case="12"] li:nth-child(12),
	&[data-active-case="13"] li:nth-child(13),
	&[data-active-case="14"] li:nth-child(14),
	&[data-active-case="15"] li:nth-child(15) {
		@include transform(translateX(0vw));
	}

	&[data-active-case="1"] li:nth-child(2),
	&[data-active-case="1"] li:nth-child(3),
	&[data-active-case="1"] li:nth-child(4),
	&[data-active-case="1"] li:nth-child(5),
	&[data-active-case="1"] li:nth-child(6),
	&[data-active-case="1"] li:nth-child(7),
	&[data-active-case="1"] li:nth-child(8),
	&[data-active-case="1"] li:nth-child(9),
	&[data-active-case="1"] li:nth-child(10),
	&[data-active-case="1"] li:nth-child(11),
	&[data-active-case="1"] li:nth-child(12),
	&[data-active-case="1"] li:nth-child(13),
	&[data-active-case="1"] li:nth-child(14),
	&[data-active-case="1"] li:nth-child(15),
	&[data-active-case="2"] li:nth-child(3),
	&[data-active-case="2"] li:nth-child(4),
	&[data-active-case="2"] li:nth-child(5),
	&[data-active-case="2"] li:nth-child(6),
	&[data-active-case="2"] li:nth-child(7),
	&[data-active-case="2"] li:nth-child(8),
	&[data-active-case="2"] li:nth-child(9),
	&[data-active-case="2"] li:nth-child(10),
	&[data-active-case="2"] li:nth-child(11),
	&[data-active-case="2"] li:nth-child(12),
	&[data-active-case="2"] li:nth-child(13),
	&[data-active-case="2"] li:nth-child(14),
	&[data-active-case="2"] li:nth-child(15),
	&[data-active-case="3"] li:nth-child(4),
	&[data-active-case="3"] li:nth-child(5),
	&[data-active-case="3"] li:nth-child(6),
	&[data-active-case="3"] li:nth-child(7),
	&[data-active-case="3"] li:nth-child(8),
	&[data-active-case="3"] li:nth-child(9),
	&[data-active-case="3"] li:nth-child(10),
	&[data-active-case="3"] li:nth-child(11),
	&[data-active-case="3"] li:nth-child(12),
	&[data-active-case="3"] li:nth-child(13),
	&[data-active-case="3"] li:nth-child(14),
	&[data-active-case="3"] li:nth-child(15),
	&[data-active-case="4"] li:nth-child(5),
	&[data-active-case="4"] li:nth-child(6),
	&[data-active-case="4"] li:nth-child(7),
	&[data-active-case="4"] li:nth-child(8),
	&[data-active-case="4"] li:nth-child(9),
	&[data-active-case="4"] li:nth-child(10),
	&[data-active-case="4"] li:nth-child(11),
	&[data-active-case="4"] li:nth-child(12),
	&[data-active-case="4"] li:nth-child(13),
	&[data-active-case="4"] li:nth-child(14),
	&[data-active-case="4"] li:nth-child(15),
	&[data-active-case="5"] li:nth-child(6),
	&[data-active-case="5"] li:nth-child(7),
	&[data-active-case="5"] li:nth-child(8),
	&[data-active-case="5"] li:nth-child(9),
	&[data-active-case="5"] li:nth-child(10),
	&[data-active-case="5"] li:nth-child(11),
	&[data-active-case="5"] li:nth-child(12),
	&[data-active-case="5"] li:nth-child(13),
	&[data-active-case="5"] li:nth-child(14),
	&[data-active-case="5"] li:nth-child(15),
	&[data-active-case="6"] li:nth-child(7),
	&[data-active-case="6"] li:nth-child(8),
	&[data-active-case="6"] li:nth-child(9),
	&[data-active-case="6"] li:nth-child(10),
	&[data-active-case="6"] li:nth-child(11),
	&[data-active-case="6"] li:nth-child(12),
	&[data-active-case="6"] li:nth-child(13),
	&[data-active-case="6"] li:nth-child(14),
	&[data-active-case="6"] li:nth-child(15),
	&[data-active-case="7"] li:nth-child(8),
	&[data-active-case="7"] li:nth-child(9),
	&[data-active-case="7"] li:nth-child(10),
	&[data-active-case="7"] li:nth-child(11),
	&[data-active-case="7"] li:nth-child(12),
	&[data-active-case="7"] li:nth-child(13),
	&[data-active-case="7"] li:nth-child(14),
	&[data-active-case="7"] li:nth-child(15),
	&[data-active-case="8"] li:nth-child(9),
	&[data-active-case="8"] li:nth-child(10),
	&[data-active-case="8"] li:nth-child(11),
	&[data-active-case="8"] li:nth-child(12),
	&[data-active-case="8"] li:nth-child(13),
	&[data-active-case="8"] li:nth-child(14),
	&[data-active-case="8"] li:nth-child(15),
	&[data-active-case="9"] li:nth-child(10),
	&[data-active-case="9"] li:nth-child(11),
	&[data-active-case="9"] li:nth-child(12),
	&[data-active-case="9"] li:nth-child(13),
	&[data-active-case="9"] li:nth-child(14),
	&[data-active-case="9"] li:nth-child(15),
	&[data-active-case="10"] li:nth-child(11),
	&[data-active-case="10"] li:nth-child(12),
	&[data-active-case="10"] li:nth-child(13),
	&[data-active-case="10"] li:nth-child(14),
	&[data-active-case="10"] li:nth-child(15),
	&[data-active-case="11"] li:nth-child(12),
	&[data-active-case="11"] li:nth-child(13),
	&[data-active-case="11"] li:nth-child(14),
	&[data-active-case="11"] li:nth-child(15),
	&[data-active-case="12"] li:nth-child(13),
	&[data-active-case="12"] li:nth-child(14),
	&[data-active-case="12"] li:nth-child(15),
	&[data-active-case="13"] li:nth-child(14), 
	&[data-active-case="13"] li:nth-child(15), 
	&[data-active-case="14"] li:nth-child(15) {
		@include transform(translateX(100vw));
	}  
}
 
.home-vid-bg-imgs {
	ul {
		width: 2000vw;
	} 

	li {
		// @include absolutefill();
		@include widthheight(100vw, 100vh);
		float: left;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	&[data-active-case="0"] ul {
		@include transform(translateX(100vw));
	}
	&[data-active-case="1"] ul {
		@include transform(translateX(0));
	}
	&[data-active-case="2"] ul {
		@include transform(translateX(-100vw));
	}
	&[data-active-case="3"] ul {
		@include transform(translateX(-200vw));
	}
	&[data-active-case="4"] ul {
		@include transform(translateX(-300vw));
	}
	&[data-active-case="5"] ul {
		@include transform(translateX(-400vw));
	}
	&[data-active-case="6"] ul {
		@include transform(translateX(-500vw));
	}
	&[data-active-case="7"] ul {
		@include transform(translateX(-600vw));
	}
	&[data-active-case="8"] ul {
		@include transform(translateX(-700vw));
	}
	&[data-active-case="9"] ul {
		@include transform(translateX(-800vw));
	}
	&[data-active-case="10"] ul {
		@include transform(translateX(-900vw));
	}
	&[data-active-case="11"] ul {
		@include transform(translateX(-1000vw));
	}
	&[data-active-case="12"] ul {
		@include transform(translateX(-1100vw));
	}
	&[data-active-case="13"] ul {
		@include transform(translateX(-1200vw));
	}
	&[data-active-case="14"] ul {
		@include transform(translateX(-1300vw));
	}
	&[data-active-case="15"] ul {
		@include transform(translateX(-1400vw));
	}
}

// .home-vid-wrap {
// 	opacity: 0;
// 	@include transition(0.2s cubic-bezier($smootheaseout));
// 	&.active {
// 		opacity: 1;
// 		@include transition(0.32s cubic-bezier($smootheaseout));
// 	}
// }

.home {
	.type-wrap {
		// margin-top: 2%;
		text-align: right;
		right: 18%; left: auto;
		width: 24%;
		.flex-vert-center > ul:nth-child(1) {
			padding-bottom: 1em;
		}
		h4 {
			&::before {
				left: auto; right: -12px;
			}
		}
		h6 {
			width: 90%;
			float: right;
		}
		.cta {
			float: right;
		}
		.title-wrap {
		    width: 160%;
		    margin-left: -70%;
		}
		.js-all-work {
			display: none;
		}
	}
	.cta-wrap {
		right: 77%;
		top: 40%;
	}
}

.home-vid-mask-wrap {
	z-index: 10;
}

.home-video-mask {
	background-color: #fff;

	.overflow-h {
		@include widthheightcentered(100%, 100%);
		@include transition(transform 0.6s cubic-bezier($sweepinginout), transform-origin 0.2s);
		@include transform(translateY(50%));
		.part {
			@include transform(translateY(-100%));
			&::before {
				border-radius: 200px;
				@include transform(scale(0.5));
			}
			&::after {

				content: '';
				border-radius: 200px;
				position: absolute;
				@include widthheight(100%, 100%);
				background-color: #fff;
				@include transition(transform 0.6s cubic-bezier($sweepinginout), transform-origin 0.2s);
				@include transform(scale(0));
			}
		}
		
	}

	.letter-n {
		li {
			&:nth-child(1) {
				width: 35.1%;
				height: 100%;
				&::before {
					@include transform(scale(1, 0));
					@include transform-origin(0 100%);
				}
				@include transform-origin(0 0%);
				// &:hover {
				// 	@include transform(scaleY(1.1));
				// }
			}
			&:nth-child(2) {
				width: 51%;
    			height: 34.8%;
    			left: 24.5%;
    			top: 32.5%;
				@include transform(rotate(53.7deg) skew(53.7deg));
			    
				&::before {
					@include transform-origin(0 100%);
					@include transform(scale(0, 1));
				}
				&:hover {
					// @include transform(scaleX(2.5));
				}
			}
			&:nth-child(3) {
				right: 0;
				left: auto;
				width: 35%;
				height: 100%;
				&::before {
					@include transform-origin(0 100%);
					@include transform(scale(1, 0));
				}
				@include transform-origin(0 100%);
				// &:hover {
				// 	@include transform(scaleY(1.1));
				// }
			}
			
		}
	}

	.letter-u {
		li {
			&:nth-child(1) {
				width: 35%;
				height: 57%;
				&::before {
					@include transform(scale(1, 0));
				}
			}
			
			&:nth-child(2) {
				
				left: 65%;
				width: 35%;
				height:57%;
				&::before {
					@include transform(scale(1, 0));
				}
			}

			&:nth-child(4) {
				width: 110%;
				height: 50%;
				overflow: hidden;
				left: 0;
				top: 57%;
				z-index: 5;
				&::before {
					top: -50%;
					border-radius: 100%;
					width: 90.9%;
					@include transform-origin(50% 50%);
				}
			}
			&:nth-child(3) {
				width: 30%;
				height: 30%;
				left: 35%;
				top: 42%;
				z-index: 10;
				&::before {
					@include pseudofill();
					border-radius: 100%;
					background-color: #FFF;
					@include transform-origin(50% 50%);
				}
				
			}
			
		}
	}

	.letter-r {
		margin-left: 4%;
		li {
			&:nth-child(1) {
				width: 35%;
				height: 100%;
				&::before {
					@include transform(scale(1, 0));
				}
			}
			&:nth-child(2) {
				left: 35%;
				width: 15%;
				height: 30%;
				&::before {
					@include transform(scale(0, 1));
				}
			}
			&:nth-child(3) {
				left: 35%;
				top: calc(50% - 1px);
				width: 15%;
				height: 30%;
				&::before {
					@include transform(scale(0, 1));
				}
			}

			&:nth-child(4) {
				top: 30%;
				left: 39%;
				width: 20%;
				height: calc(20% - 1px);
				// overflow: hidden;
				z-index: 5;
				&::before {
					@include pseudofill();
					border-radius: 100%;
					background-color: #FFF;
					@include transform-origin(50% 50%);
				}
			}
			
			&:nth-child(5) {
				top: 0;
				left: 49%;
				width: 80%;
				height: 80%;
				overflow: hidden;
				z-index: 2;
				&::before {
					left: -50%;	
					border-radius: 100%;
					@include transform-origin(50% 50%);
				}
			}

			
			&:nth-child(6) {
				width: 35%;
				height: 40%;
				left: 45%;
				top: 60%;
				@include transform(skew(30deg));
				&::before {
									
					@include transform(scale(1, 0));
				}
				
			}
			
		}
	}

	.letter-t {
		li {
			&:nth-child(1), &:nth-child(2), &:nth-child(3)  {
				width: 100%;
				height: 12%;
				&::before {
					@include transform(scale(0, 1));
					@include transform-origin(0 0%);
				}
			}
			&:nth-child(2) { 
				top: 12%;
				&::before {
				}
			}
			&:nth-child(3) {
				top: 24%;
				&::before {
				}
			}

			&:nth-child(4), &:nth-child(5), &:nth-child(6)  {
				height: 66%;
				top: 34%; 
				width: 12.2%;
				&::before {
					@include transform(scale(1, 0));
					@include transform-origin(0 100%);
				}
			}
			&:nth-child(4) {
				left: 32%;
			}
			&:nth-child(5) {
				left: 44%;
			}
			&:nth-child(6) {
				left: 56%;
			}
			
		}
	}

	.letter-e {
		// @include transform(scale(0.87, 1.05));
		li {
			&:nth-child(1) {
				left: 0;
				width: 33%;
				height: 100%;
				&::before {
					@include transform(scale(1, 0));
				}
			}
			&:nth-child(2), &:nth-child(3), &:nth-child(4) {
				width: 85%;
				left: 0;
				height: 25%;
				&::before {
					@include transform(scale(0, 1));
					@include transform-origin(0 0%);
				}
			}

			&:nth-child(3) {
				top: 40%;
				height: 20%;
				width: 75%;
			}
			
			&:nth-child(4) {
				top: 75%;
			}
			
		}
	}

	.letters {

		ul, li {
			position: absolute; top:0; left: 0; @include widthheightboth(100%);
		}

		ul {
			pointer-events: none;
		}

		li {
			@include widthheightboth(0%);
			@include transition(0.55s cubic-bezier($sweepinginout));
			&::before {
				@include pseudofill();
				background-color: #000;
				@include transform(scale(0, 0));
				@include transform-origin(0 0);
				-webkit-backface-visibility: hidden;
			}
			@for $i from 1 through 8 {
			    &:nth-child(#{$i}) {
			    	$tdelay: ($i*0.05-0.05s);
			    	// @include transition-delay($tdelay);    
			    	&::before {
						@include transition(0.31s cubic-bezier($sweepinginout) $tdelay);    
					}
			    }
			}

		}

		&[data-active-letter="n"] ul.letter-n li::before,
		&[data-active-letter="u"] ul.letter-u li::before,
		&[data-active-letter="r"] ul.letter-r li::before,
		&[data-active-letter="t"] ul.letter-t li::before,
		&[data-active-letter="e"] ul.letter-e li::before {
			@include transform(scale(1, 1));
		}
		
		&[data-active-letter="n"] ul.letter-n,
		&[data-active-letter="u"] ul.letter-u,
		&[data-active-letter="r"] ul.letter-r,
		&[data-active-letter="t"] ul.letter-t,
		&[data-active-letter="e"] ul.letter-e {
			pointer-events: auto;
			li {
				&:hover {
					cursor: pointer;
				}
			}
		}
		
		&[data-active-letter="n"] ul.letter-n li,
		// &[data-active-letter="u"] ul.letter-u li,
		// &[data-active-letter="r"] ul.letter-r li,
		&[data-active-letter="t"] ul.letter-t li,
		&[data-active-letter="e"] ul.letter-e li {
			@for $i from 1 through 8 {
			    &:nth-child(#{$i}) {
			    	$tdelay: ($i*0.25-0.05s);
			    	// @include transition-delay($tdelay);    
			    	&::before {
						@include transition(0.65s cubic-bezier($sweepinginout) $tdelay);    
					}
			    }
			}
		}

		&[data-active-letter="r"] ul.letter-r li {
		    &:nth-child(1) {  
		    	&::before {
					@include transition(0.65s cubic-bezier($sweepinginout) 0.2s);    
				}
		    }
		    &:nth-child(2) {  
		    	&::before {
					@include transition(0.65s cubic-bezier($sweepinginout) 0.45s);    
				}
		    }
		    &:nth-child(3) {  
		    	&::before {
					@include transition(0.65s cubic-bezier($sweepinginout) 0.56s);    
				}
		    }
		    &:nth-child(4) {  
		    	&::before {
					@include transition(0.65s cubic-bezier($sweepinginout) 0.2s);    
				}
		    }
		    &:nth-child(5) {  
		    	&::before {
					@include transition(0.65s cubic-bezier($sweepinginout) 0.9s);    
				}
		    }
		    &:nth-child(6) {  
		    	&::before {
					@include transition(0.55s cubic-bezier($sweepinginout) 0.95s);    
				}
		    }
		}

		&[data-active-letter="u"] ul.letter-u li {
		    &:nth-child(1) {  
		    	&::before {
					@include transition(0.65s cubic-bezier($sweepinginout) 0.25s);    
				}
		    }
		    &:nth-child(2) {  
		    	&::before {
					@include transition(0.65s cubic-bezier($sweepinginout) 0.5s);    
				} 
		    }
		    &:nth-child(3) {  
		    	&::before {
					@include transition(0.65s cubic-bezier($sweepinginout) 0s);    
				}
		    }
		    &:nth-child(4) {  
		    	&::before {
		    		@include transform(scale(1,2));
					@include transition(0.45s cubic-bezier($sweepinginout) 0.6s);    
				}
		    }
		}

	}

}

.home .grid {
	li {
		&::before {
			background-color: rgba(221,221,221,0.2);
		}
		
		@media only screen and (min-width: $mobile-desktop) {
			&:nth-child(1) {
				left: 23%;
			}
			&:nth-child(2) {
				left: 56.1%;
			}
			&:nth-child(3) {
				left: 81.9%;
			}
		}
		// @media only screen and (max-width: $mobile-desktop) {
		// 	&:nth-child(1) {
		// 		left: 2.95%;
		// 	}
		// 	&:nth-child(2) {
		// 		left: 11.95%;
		// 	}
		// 	&:nth-child(3) {
		// 		left: 87.75%;
		// 	}
		// 	&:nth-child(4) {
		// 		left: 96.95%;
		// 	}
		// }
	}

}

@media only screen and (max-width: $mobile-desktop) {
	.home .type-wrap {
		right: 10%;
	    left: auto;
	    width: 70%;
		.title-wrap {
		    width: 115%;
		    margin-left: -25%;
		}
	}

	.home-video-mask {
		background-color: rgba(255,255,255,0.9);
	}
	.home-vid-bg-imgs ul {
		@include transition(0.85s cubic-bezier($sweepinginout) 0.2s);    
	}
	.fixed-elements .next-prev-arrows {
		display: block;
		right: 50%;
		bottom: 5%;
		top: auto;
		@include display(flex);
	    width: 120px;
	    margin-right: -60px;
		li {
			@include widthheightboth(60px);
			&::after {
				display: none;
			}
		}
	}
}

html.touchevents {
	
	.home-vid-bg-imgs ul {
		@include transition(0.85s cubic-bezier($sweepinginout) 0.2s);    
	}
}